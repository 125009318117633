import { useSearchParams, Routes, Route } from "react-router-dom";

import LiveCall from "./pages/LiveCall";
import Admin from "./pages/Admin";
import Home from "./pages/Home";
import AddCustomer from "./pages/AddCustomer";
import Header from "./components/Header";

export default function AppRoutes() {
  const [searchParams] = useSearchParams();
  const embedded = searchParams.get("embedded") === "true";

  return (
    <>
      {!embedded && <Header />}
      <div
        style={{
          height: !embedded ? "calc(100vh - 64px)" : "100vh",
          width: "100%",
        }}
      >
        <div
          style={{
            height: "100%",
            maxHeight: "100%",
            width: "100%",
            maxWidth: "1500px",
            margin: "0 auto",
          }}
        >
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/live-call" element={<LiveCall />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="/add-customer" element={<AddCustomer />} />
          </Routes>
        </div>
      </div>
    </>
  );
}

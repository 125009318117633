import { Typography } from "@mui/material";

export default function Summary({ summary }) {
  if (!summary) return <></>;

  return (
    <>
      <Typography variant="p">{summary}</Typography>
    </>
  );
}

import { Alert, Snackbar } from "@mui/material";

export default function AlertSnackbar({ open, onClose, severity, children }) {
  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={onClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      <Alert
        onClose={onClose}
        severity={severity || "success"}
        sx={{ width: "100%" }}
      >
        {children}
      </Alert>
    </Snackbar>
  );
}

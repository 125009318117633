import axios from "axios";

const API_URL = `https://${process.env.REACT_APP_BACKEND_URL}/api`;

export const getConfig = () => {
  return axios.get(`${API_URL}/config`).then((response) => {
    return response.data;
  });
};

export const updateConfig = (config) => {
  return axios.put(`${API_URL}/config`, config).then((response) => {
    return response.data;
  });
};

export const getCustomers = () => {
  return axios.get(`${API_URL}/customers`).then((response) => {
    return response.data;
  });
};

export const addCustomer = (customer) => {
  return axios.post(`${API_URL}/customers`, customer);
};

export const deleteCustomer = (customerId) => {
  return axios.delete(`${API_URL}/customers/${customerId}`);
};

export const getBrands = () => {
  return axios.get(`${API_URL}/brands`).then((response) => {
    return response.data;
  });
};

export const getKnowledgeBase = (brand) => {
  return axios.get(`${API_URL}/knowledge-base`).then((response) => {
    return response.data;
  });
};

export const getWebappUrl = (brand) => {
  return axios.get(`${API_URL}/webapp-url`).then((response) => {
    return response.data;
  });
};

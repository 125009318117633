import { useEffect, useState } from "react";
import { Autocomplete, Box, Button, Stack, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

import { getCustomers } from "../api";

export default function CustomerDetails({ customer, onChange }) {
  const [customers, setCustomers] = useState([]);

  useEffect(() => {
    getCustomers().then((customers) => {
      setCustomers(customers);
    });
  }, []);

  return (
    <Box>
      <Stack spacing={2} sx={{ marginTop: "10px" }}>
        {customer === null && (
          <>
            <Autocomplete
              options={customers}
              getOptionLabel={(option) =>
                option.firstName + " " + option.lastName
              }
              renderInput={(params) => (
                <TextField {...params} label="Customer" variant="outlined" />
              )}
              onChange={(event, value) => {
                onChange(value);
              }}
              size="small"
            />
            <Button variant="contained" onClick={() => onChange({})}>
              Add new customer
            </Button>
          </>
        )}
        {!!customer && (
          <>
            <TextField
              label="First name"
              variant="outlined"
              value={customer.firstName}
              size="small"
              autoComplete="off"
              onChange={(event) => {
                onChange({
                  ...customer,
                  firstName: event.target.value,
                });
              }}
            />
            <TextField
              label="Last name"
              variant="outlined"
              value={customer.lastName}
              size="small"
              autoComplete="off"
              onChange={(event) => {
                onChange({
                  ...customer,
                  lastName: event.target.value,
                });
              }}
            />
            <DatePicker
              label="Date of birth"
              value={dayjs(customer.dateOfBirth)}
              size="small"
              onChange={(value) => {
                onChange({
                  ...customer,
                  dateOfBirth: dayjs(value).format("YYYY-MM-DD"),
                });
              }}
            />
          </>
        )}
      </Stack>
    </Box>
  );
}

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { useWebSocket } from "react-use-websocket/dist/lib/use-websocket";
import PageTitle from "../components/PageTitle";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router-dom";

const WEBSOCKET_URL = `wss://${process.env.REACT_APP_BACKEND_URL}/front`;

function ActiveCallsTable({ activeCalls }) {
  const navigate = useNavigate();

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Caller Phone Number</TableCell>
            <TableCell>Caller Name</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {activeCalls.map((call, index) => (
            <TableRow
              key={index}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                cursor: "pointer",
              }}
              onClick={() => navigate("/live-call")}
            >
              <TableCell component="th" scope="row">
                {call.callerPhoneNumber}
              </TableCell>

              <TableCell component="th" scope="row">
                {!!call.caller
                  ? call.caller.firstName + " " + call.caller.lastName
                  : "Unknown"}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default function Home() {
  const [activeCalls, setActiveCalls] = useState([]);

  const handleMessage = (event) => {
    const data = JSON.parse(event.data);

    if (data.event === "call-started") {
      setActiveCalls([
        { callerPhoneNumber: data.callerPhoneNumber, caller: data.caller },
      ]);
    }
  };

  useWebSocket(WEBSOCKET_URL, {
    onMessage: handleMessage,
    onError: (event) => console.log("error", event),
  });

  return (
    <>
      <PageTitle title="Active Calls" />

      {activeCalls.length === 0 && (
        <Box sx={{ marginTop: "10px" }}>
          <Typography variant="p" color={"#555"}>
            No active calls.
          </Typography>
        </Box>
      )}
      {activeCalls.length > 0 && <ActiveCallsTable activeCalls={activeCalls} />}
    </>
  );
}

import { useEffect, useState } from "react";
import {
  getConfig,
  updateConfig,
  getCustomers,
  getBrands,
  deleteCustomer,
} from "../api";
import {
  Box,
  Button,
  Stack,
  TextField,
  CircularProgress,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import { Link } from "react-router-dom";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import MenuItem from "@mui/material/MenuItem";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";

import SectionTitle from "../components/SectionTitle";
import AlertSnackbar from "../components/AlertSnackbar";
import PageTitle from "../components/PageTitle";

function CustomersTable({ customers, onDeleteCustomer }) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>firstName</TableCell>
            <TableCell>lastName</TableCell>
            <TableCell>dateOfBirth</TableCell>
            <TableCell>phoneNumber</TableCell>
            <TableCell align="center">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {customers.map((customer) => (
            <TableRow
              key={customer.customerId}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {customer.firstName}
              </TableCell>

              <TableCell component="th" scope="row">
                {customer.lastName}
              </TableCell>

              <TableCell component="th" scope="row">
                {customer.dateOfBirth}
              </TableCell>

              <TableCell component="th" scope="row">
                {customer.phoneNumber}
              </TableCell>
              <TableCell component="th" scope="row" align="center">
                <IconButton
                  onClick={() => onDeleteCustomer(customer.customerId)}
                  color="error"
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default function Admin() {
  const [loading, setLoading] = useState(true);
  const [brands, setBrands] = useState([]);
  const [config, setConfig] = useState({
    phoneNumber: "",
    language: "",
    brand: "",
  });
  const [customers, setCustomers] = useState(null);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    Promise.all([getConfig(), getCustomers(), getBrands()]).then(
      ([config, customers, brands]) => {
        setConfig(config);
        setCustomers(customers);
        setBrands(brands);
        setLoading(false);
      }
    );
  }, []);

  const onSave = () => {
    updateConfig(config)
      .then(() => {
        setSuccess(true);
      })
      .catch(() => {
        setError(true);
      });
  };

  const onDeleteCustomer = (customerId) => {
    deleteCustomer(customerId)
      .then(() => {
        setCustomers(customers.filter((c) => c.customerId !== customerId));
        setSuccess(true);
      })
      .catch(() => {
        setError(true);
      });
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          paddingTop: "30px",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <Box sx={{ padding: "10px" }}>
        <PageTitle title="Admin" />

        <Stack sx={{ marginTop: "10px" }}>
          <Stack direction="row" spacing={2}>
            <TextField
              label="Agent phone number"
              value={config.phoneNumber}
              onChange={(e) =>
                setConfig({ ...config, phoneNumber: e.target.value })
              }
              variant="outlined"
            />

            <FormControl>
              <InputLabel>Language</InputLabel>
              <Select
                label="Language"
                value={config.language}
                onChange={(e) => {
                  console.log(e.target.value);
                  setConfig({ ...config, language: e.target.value });
                }}
              >
                <MenuItem value={"fr-FR"}>fr-FR</MenuItem>
                <MenuItem value={"en-US"}>en-US</MenuItem>
                <MenuItem value={"ar-SA"}>ar-SA</MenuItem>
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel>Brand</InputLabel>
              <Select
                label="Brand"
                value={config.brand}
                onChange={(e) =>
                  setConfig({ ...config, brand: e.target.value })
                }
              >
                {brands.map((brand) => (
                  <MenuItem key={brand} value={brand}>
                    {brand}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button variant="outlined" onClick={onSave}>
              Save
            </Button>
          </Stack>
        </Stack>
        <SectionTitle title="Customers" />
        <CustomersTable
          customers={customers}
          onDeleteCustomer={onDeleteCustomer}
        />
        <Link to="/add-customer">
          <Button variant="contained" sx={{ marginTop: "10px" }}>
            Add Customer
          </Button>
        </Link>
      </Box>
      <AlertSnackbar open={success} onClose={() => setSuccess(false)}>
        Saved
      </AlertSnackbar>
      <AlertSnackbar
        open={error}
        onClose={() => setError(false)}
        severity="error"
      >
        Error
      </AlertSnackbar>
    </>
  );
}

import { Typography } from "@mui/material";

export default function PageTitle({ title }) {
  return (
    <Typography
      variant="h4"
      component={"h1"}
      sx={{ marginBottom: "20px", marginTop: "10px" }}
    >
      {title}
    </Typography>
  );
}

import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";

export default function TopicAnalysis({ topicAnalysis }) {
  const SENTIMENT_COLORS = {
    POSITIVE: "success",
    NEGATIVE: "error",
    NEUTRAL: "default",
  };

  if (!topicAnalysis) return <></>;

  return (
    <>
      <Stack direction="row" spacing={1} wrap="wrap">
        {(topicAnalysis || []).map((topic, index) => (
          <Chip
            key={index}
            label={topic.topic}
            color={SENTIMENT_COLORS[topic.sentiment]}
          />
        ))}
      </Stack>
    </>
  );
}

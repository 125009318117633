import React, { useEffect, useState } from "react";
import useWebSocket from "react-use-websocket";
import Conversation from "./../components/Conversation";
import MuiGrid from "@mui/material/Grid";
import CustomerDetails from "./../components/CustomerDetails";
import KnowledgeBase from "./../components/KnowledgeBase";
import CustomCard from "./../components/CustomCard";
import { styled } from "@mui/material/styles";
import { Box, Divider } from "@mui/material";
import TopicAnalysis from "../components/TopicAnalysis";
import ReactMarkdown from "react-markdown";
import Summary from "../components/Summary";
import { getKnowledgeBase, getWebappUrl } from "../api";

const WEBSOCKET_URL = `wss://${process.env.REACT_APP_BACKEND_URL}/front`;

const Grid = styled(MuiGrid)(({ theme }) => ({
  width: "100%",
  ...theme.typography.body2,
  '& [role="separator"]': {
    margin: 0,
  },
}));

export default function LiveCall() {
  const [customer, setCustomer] = useState(null);
  const [summary, setSummary] = useState();
  const [transcript, setTranscript] = useState([]);
  const [topicAnalysis, setTopicAnalysis] = useState();
  const [advice, setAdvice] = useState([]);
  const [knowledgeBase, setKnowledgeBase] = useState([]);
  const [webappUrl, setWebappUrl] = useState("");

  useEffect(() => {
    getKnowledgeBase().then((knowledgeBase) => {
      setKnowledgeBase(knowledgeBase);
    });

    getWebappUrl().then((webappUrl) => {
      setWebappUrl(webappUrl);
    });
  }, []);

  function handleMessage(event) {
    const data = JSON.parse(event.data);
    console.log(data);
    if (data.event === "transcript-recognized") {
      setTranscript(data.transcript);

      if (data.caller) {
        setCustomer(data.caller);
      }
    }
    if (data.event === "analysis-updated") {
      setTopicAnalysis(data.analysis.topic_analysis);
      setSummary(data.analysis.summary);
    }
    if (data.event === "advice-updated") {
      setAdvice((x) => [...x, data.advice]);
    }
  }

  useWebSocket(WEBSOCKET_URL, {
    onMessage: handleMessage,
    onError: (event) => console.log("error", event),
  });

  return (
    <>
      <Box sx={{ height: "100%", padding: "20px", boxSizing: "border-box" }}>
        <Grid
          container
          columns={24}
          spacing={3}
          style={{ marginTop: 0, marginLeft: 0 }}
          direction={"row"}
          alignItems={"stretch"}
        >
          <Grid
            item
            xs={10}
            lg={10}
            style={{
              height: "100%",
              margin: 0,
              paddingLeft: 0,
              paddingRight: "10px",
              paddingTop: 0,
            }}
          >
            <Grid container direction={"column"} spacing={[0, 2]}>
              <Grid item xs={12} lg={12} style={{ height: "100%", margin: 0 }}>
                <CustomCard title={"Live Transcription"} height={300}>
                  <Conversation messages={transcript} />
                </CustomCard>
              </Grid>
              <Grid item xs={12} lg={12} style={{ height: "100%", margin: 0 }}>
                <CustomCard title={"Smart script"} height={350}>
                  <div style={{ overflow: "auto", maxHeight: "350px" }}>
                    {advice.map((item, index, arr) => (
                      <React.Fragment key={index}>
                        <ReactMarkdown children={item} key={index} />
                        <Divider />
                        {index === arr.length - 1 && (
                          <div
                            ref={(el) => {
                              if (el) el.scrollIntoView({ behavior: "smooth" });
                            }}
                          />
                        )}
                      </React.Fragment>
                    ))}
                  </div>
                </CustomCard>
              </Grid>
            </Grid>
          </Grid>
          <Divider orientation="vertical" flexItem sx={{ mr: "-1px" }} />
          <Grid
            item
            xs={7}
            lg={7}
            style={{
              height: "100%",
              margin: 0,
              paddingLeft: "10px",
              paddingRight: "10px",
              paddingTop: 0,
            }}
          >
            <Grid container direction={"column"} spacing={[0, 2]}>
              <Grid item xs={12} lg={12} style={{ height: "100%", margin: 0 }}>
                <CustomCard title={"Knowledge base"} height={250}>
                  <KnowledgeBase knowledge_base={knowledgeBase} />
                </CustomCard>
              </Grid>
              <Grid item xs={12} lg={12} style={{ height: "100%", margin: 0 }}>
                <CustomCard title={"AI Assistant"} height={500}>
                  <iframe
                    title="alloreview"
                    src={webappUrl}
                    style={{
                      width: "100%",
                      height: "100%",
                      border: "none",
                      margin: 0,
                    }}
                  ></iframe>
                </CustomCard>
              </Grid>
            </Grid>
          </Grid>
          <Divider orientation="vertical" flexItem sx={{ mr: "-1px" }} />

          <Grid
            item
            xs={7}
            lg={7}
            style={{
              height: "100%",
              margin: 0,
              paddingLeft: "10px",
              paddingRight: "10px",
              paddingTop: 0,
            }}
          >
            <Grid container direction={"column"} spacing={[0, 2]}>
              <Grid item xs={12} lg={12} style={{ height: "100%", margin: 0 }}>
                <CustomCard title={"Topic analysis"} height={150}>
                  <TopicAnalysis topicAnalysis={topicAnalysis} />
                </CustomCard>
              </Grid>
              <Grid item xs={12} lg={12} style={{ height: "100%", margin: 0 }}>
                <CustomCard title={"Summary"} height={150}>
                  <Summary summary={summary} />
                </CustomCard>
              </Grid>
              <Grid item xs={12} lg={12} style={{ height: "100%", margin: 0 }}>
                <CustomCard title={"Customer details"} height={300}>
                  <CustomerDetails customer={customer} onChange={setCustomer} />
                </CustomCard>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

import styled from "@emotion/styled";
import { useEffect, useRef } from "react";
import Typography from "@mui/material/Typography";

const Bubble = styled.div`
  background-color: ${(props) =>
    props.speaker === "agent"
      ? "#1976d2"
      : props.speaker === "bot"
      ? "#d9fdd3"
      : "#e0e0e0"};
  color: ${(props) => (props.speaker === "agent" ? "white" : "black")};
  padding: 10px 15px;
  border-radius: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: fit-content;
  max-width: 80%;
`;

const ConversationBubble = ({ message, speaker }) => {
  const style =
    speaker === "agent" || speaker === "bot"
      ? { marginRight: "0", marginLeft: "auto" }
      : {};

  return (
    <Bubble speaker={speaker} style={style}>
      <Typography>{message}</Typography>
    </Bubble>
  );
};

export default function Conversation({ messages }) {
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [messages]);

  return (
    <>
      {messages
        .filter((message) => message.transcript !== "")
        .map((message, index) => (
          <div key={index}>
            <ConversationBubble
              message={message.transcript}
              speaker={message.speaker}
            />
          </div>
        ))}
      <div ref={messagesEndRef} />
    </>
  );
}

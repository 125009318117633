import { Typography } from "@mui/material";

export default function SectionTitle({ title }) {
  return (
    <Typography
      variant="h6"
      component={"div"}
      sx={{ marginBottom: "5px", marginTop: "5px" }}
    >
      {title}
    </Typography>
  );
}

import { addCustomer } from "../api";
import { useState } from "react";
import { Button, TextField, Box, Stack } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import dayjs from "dayjs";
import PageTitle from "../components/PageTitle";
import AlertSnackbar from "../components/AlertSnackbar";

export default function AddCustomer() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const onFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };

  const onLastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const onDateOfBirthChange = (value) => {
    setDateOfBirth(dayjs(value).format("YYYY-MM-DD"));
  };

  const onPhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  const onCustomerSave = () => {
    addCustomer({ firstName, lastName, dateOfBirth, phoneNumber })
      .then(() => {
        setSuccess(true);
      })
      .catch(() => {
        setError(true);
      });
  };

  return (
    <>
      <Box sx={{ width: "300px" }}>
        <PageTitle title={"Add Customer"} />
        <Stack spacing={2}>
          <TextField
            label="First Name"
            value={firstName}
            onChange={onFirstNameChange}
            autoComplete="off"
          />
          <TextField
            label="Last Name"
            value={lastName}
            onChange={onLastNameChange}
            autoComplete="off"
          />

          <DatePicker
            label="Date of birth"
            value={!!dateOfBirth ? dayjs(dateOfBirth) : null}
            onChange={onDateOfBirthChange}
          />
          <TextField
            label="Phone Number"
            value={phoneNumber}
            onChange={onPhoneNumberChange}
            autoComplete="off"
          />
          <Button variant="contained" onClick={onCustomerSave}>
            Save
          </Button>
        </Stack>
      </Box>

      <AlertSnackbar open={success} onClose={() => setSuccess(false)}>
        Saved
      </AlertSnackbar>
      <AlertSnackbar
        open={error}
        onClose={() => setError(false)}
        severity="error"
      >
        Error
      </AlertSnackbar>
    </>
  );
}

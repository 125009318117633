import styled from "@emotion/styled";
import React from "react";

const CardContainer = styled.div`
  width: 100%;
  border: 0.5px solid rgba(0, 0, 0, 0.125);
  background-color: #fff;
  height: ${(props) => props.height}px;
  display: flex;
  flex-direction: column;
`;

const CardHeader = styled.div`
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.125);
  color: #333;
  font-size: 0.9rem;
  font-weight: 400;
`;

const CardBody = styled.div`
  flex: 1 1 auto;
  padding: 1rem;
  color: #212529;
  overflow-y: auto;
`;

export default function CustomCard({ title, children, height }) {
  return (
    <CardContainer height={height}>
      <CardHeader>{title}</CardHeader>
      <CardBody>{children}</CardBody>
    </CardContainer>
  );
}

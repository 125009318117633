// Desc: Knowledge Base component
// This component is used to display links to the knowledge base

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

export default function KnowledgeBase({ knowledge_base }) {
  return (
    <>
      <List sx={{ width: "100%", bgcolor: "background.paper" }}>
        {knowledge_base.map((link, index) => (
          <ListItem disablePadding key={index}>
            <ListItemButton component="a" href={link.link} target="_blank">
              <ListItemText primary={link.title} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </>
  );
}
